








import { Vue, Component } from "vue-property-decorator";
import ErrorTemplate from "@/components/error/ErrorTemplate.vue";

@Component({
  components: {
    "error-template": ErrorTemplate
  }
})
export default class NotFound extends Vue {}
